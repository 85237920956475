
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'colors';

// Definir las fuentes principales
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// Definir los tamaños de fuente base
$font-size-xs: 10px;   // Extra pequeño para etiquetas o anotaciones
$font-size-small: 12px;  // Pequeño
$font-size-base: 16px;   // Base (texto principal)
$font-size-medium: 18px; // Medio (subtítulos o texto destacado)
$font-size-large: 24px;  // Grande (títulos de secciones)
$font-size-xlarge: 32px; // Muy grande (encabezados principales)

// Definir los pesos de las fuentes
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Definir estilos para responsive (puntos de ruptura)
$small-screen: 576px;   // Teléfonos pequeños
$medium-screen: 768px;  // Tablets
$large-screen: 992px;   // Desktops
$xlarge-screen: 1200px; // Desktops grandes

// Estilos base para el cuerpo
body {
  font-family: $primary-font !important;
  font-size: $font-size-base !important;
  font-weight: $font-weight-regular !important;
  line-height: 1.6 !important;
  color: $black-medium !important; // Color del texto
}

// Encabezados (h1, h2, h3) con Montserrat
h1, h2, h3 {
  font-family: $secondary-font;
  font-weight: $font-weight-bold;
  line-height: 1.3;
  color: $gray-black; // Color más oscuro para títulos
}

// Estilos específicos para diferentes niveles de encabezados
h1 {
  font-size: $font-size-xlarge;
}

h2 {
  font-size: $font-size-large;
}

h3 {
  font-size: $font-size-medium;
}

// Párrafos y texto general
p {
  font-family: $primary-font;
  font-size: $font-size-base;
  color: $black-medium;
}

// Texto pequeño o detalles
small {
  font-size: $font-size-small;
  color: $gray-dark;
}

// Estilo para subrayado y negrita
.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: $font-weight-bold;
}

// Estilos responsivos
@media (max-width: $small-screen) {
  body {
    font-size: $font-size-small !important; // Ajustar el tamaño del texto en pantallas pequeñas
  }
  
  h1 {
    font-size: 24px !important; // Ajustar los encabezados en pantallas pequeñas
  }
  
  h2 {
    font-size: 20px !important;
  }
}

@media (min-width: $medium-screen) and (max-width: $large-screen) {
  body {
    font-size: $font-size-medium !important; // Ajustar el tamaño del texto en tablets
  }
  
  h1 {
    font-size: 28px !important;
  }
  
  h2 {
    font-size: 24px !important;
  }
}

@media (min-width: $large-screen) {
  body {
    font-size: $font-size-base !important; // Volver al tamaño base en pantallas grandes
  }
  
  h1 {
    font-size: $font-size-xlarge !important; // Tamaño más grande para encabezados en pantallas grandes
  }
  
  h2 {
    font-size: $font-size-large !important;
  }
}

// Texto en negrita y subrayado para enfatizar
.bold {
  font-weight: $font-weight-bold;
}

.underline {
  text-decoration: underline;
}